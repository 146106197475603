import React, { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import {
  AiaMutationAnalyzePitchUrlArgs,
  AiaMutationUploadArgs,
  AiaPitchDeckResult,
  PitchDeckUrlType,
} from "gql/graphql";
import { AIA_UPLOAD, ANALYZE_PITCHDECK_URL } from "./queries";
import Button from "atoms/Button/Button";
import { PitchDeckUrlInput } from "./UploadPitchDeck";
import {
  mixpanelAiaDocSendUpload,
  mixpanelAiaUpload,
} from "core/mixpanel/Mixpanel";
import { getUser } from "models/user/selectors";
import PitchDeckURL from "./PitchDeckURL";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/cn";

const Dropdown: FC<{
  trigger: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}> = ({ trigger, content, isOpen, setIsOpen }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      {trigger}
      {isOpen && (
        <div className="absolute z-10 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {content}
          </div>
        </div>
      )}
    </div>
  );
};

const PitchDeckPdf: FC = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pitchUrl, setPitchUrl] = useState<PitchDeckUrlInput>({
    type: null,
    value: "",
  });

  const [pitchUrlError, setPitchUrlError] = useState(false);
  const user = useSelector(getUser);

  const handlePitchSuccess = (slug: string, id: string) => {
    mixpanelAiaUpload(user, slug);
    navigate(`/aia-profile/${slug}/${id}`);
  };

  const [uploadPdf, { loading: uploadPdfLoading, error: uploadPdfError }] =
    useMutation<{ aia: { upload: AiaPitchDeckResult } }, AiaMutationUploadArgs>(
      AIA_UPLOAD,
      {
        onCompleted: (data) => {
          const slug = data?.aia?.upload?.slug;
          const id = data?.aia?.upload?.id;
          if (slug && id) {
            handlePitchSuccess(slug, id);
          }
        },
      }
    );

  const [
    analyzePitchUrl,
    { loading: analyzePitchUrlLoading, error: analyzePitchUrlError },
  ] = useMutation<
    { aia: { analyzePitchUrl: AiaPitchDeckResult } },
    AiaMutationAnalyzePitchUrlArgs
  >(ANALYZE_PITCHDECK_URL, {
    onCompleted: (data) => {
      const slug = data?.aia?.analyzePitchUrl?.slug;
      const id = data?.aia?.analyzePitchUrl?.id;
      if (slug && id) {
        if (pitchUrl.type === PitchDeckUrlType.Docsend) {
          mixpanelAiaDocSendUpload(user, slug);
        }
        handlePitchSuccess(slug, id);
      }
    },
  });

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && file.type === "application/pdf") {
      try {
        await uploadPdf({
          variables: {
            pdf: file,
          },
        });
      } catch (error) {
        console.error("Error uploading PDF:", error);
      }
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handlePitchUrlChange = (newPitchUrl: PitchDeckUrlInput) => {
    setPitchUrl(newPitchUrl);
    if (newPitchUrl.type && newPitchUrl.value) {
      analyzePitchUrl({
        variables: {
          type: newPitchUrl.type,
          url: newPitchUrl.value,
        },
      });
    }
  };

  const toggleDropdown = () => {
    if (!isLoading) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const isLoading = uploadPdfLoading || analyzePitchUrlLoading;

  const dropdownContent = (
    <div className="p-6">
      <Button
        onClick={handleUploadClick}
        loading={uploadPdfLoading}
        className="flex w-full bg-black mb-6 justify-center text-white font-bold p-2 rounded-3xl hover:opacity-90"
        disabled={uploadPdfLoading || analyzePitchUrlLoading}
      >
        <span className="font-bold text-xl">Upload PDF file</span>
      </Button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="application/pdf"
        style={{ display: "none" }}
      />
      <PitchDeckURL
        url={pitchUrl}
        setUrl={handlePitchUrlChange}
        urlError={pitchUrlError}
        setUrlError={setPitchUrlError}
        label="Docsend, pitch.com or Google Drive url"
        placeholder="Paste your url here"
        disabled={uploadPdfLoading || analyzePitchUrlLoading}
      />
    </div>
  );

  return (
    <>
      <div className={cn("relative")}>
        <Dropdown
          trigger={
            <Button
              onClick={toggleDropdown}
              loading={isLoading}
              className="flex w-full bg-black justify-center mt-6 font-bold text-white p-2 rounded-3xl hover:opacity-90"
              disabled={isLoading}
            >
              <span className="font-bold text-xl">Upload pitch deck</span>
            </Button>
          }
          content={dropdownContent}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
        />
      </div>

      {(uploadPdfError || analyzePitchUrlError) && (
        <span className="mt-4 text-red-500">
          Something went wrong. Please try again later
        </span>
      )}
    </>
  );
};

export default PitchDeckPdf;
